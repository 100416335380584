import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";
import { useRef } from 'react';

const Login = () => {

    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    function authClick(e) {

        const json = JSON.stringify({ email: emailRef.current.value, password: passwordRef.current.value });

        axios.post('https://api.nanacoaching.fr/users/login/', json)
            .then(resp => {

                let id = resp.data.id;

                if (id !== undefined && id !== null) {

                    localStorage.setItem("tid", id);
                    localStorage.setItem("ta", emailRef.current.value);

                    const MySwal = withReactContent(Swal)

                    MySwal.fire({
                        title: "Parfait",
                        text: "Vous avez bien été identifier !",
                        icon: 'success'
                    }).then(() => {
                        window.location.href = "/";
                    })


                } else {

                    const MySwal = withReactContent(Swal)

                    MySwal.fire({
                        title: "Oups...",
                        text: "Votre Mot de passe ou email est incorrect !",
                        icon: 'error'
                    })

                }

            }).catch((error) => {
                console.log({ ...error })
            });

    }

    return (
        <div className="page page--login" data-page="login">
            {/* HEADER */}
            <header className="header header--fixed">
                <div className="header__inner">
                    <div className="header__icon">
                        <a href="/home">
                            <img
                                src="https://cdn-img.nanacoaching.fr/arrow-back.svg"
                                alt=""
                                title=""
                            />
                        </a>
                    </div>
                </div>
            </header>
            <div className="login">
                <div className="login__content">
                    <h2 className="login__title uppc">CONNEXION</h2>
                    <div className="login-form">
                        <div className="login-form__row">
                            <label className="login-form__label">Email</label>
                            <input
                                ref={emailRef}
                                type="email"
                                name="Email"
                                defaultValue=""
                                className="login-form__input required"
                            />
                        </div>
                        <div className="login-form__row">
                            <label className="login-form__label">Mot de passe</label>
                            <input
                                ref={passwordRef}
                                type="password"
                                name="password"
                                defaultValue=""
                                className="login-form__input required"
                            />
                        </div>
                        <div className="login-form__forgot-pass">
                            <a href="forgot-password.html">Mot de passe oublié?</a>
                        </div>
                        <div className="login-form__row">
                            <input
                                type="submit"
                                name="submit"
                                className="login-form__submit button button--main button--full"
                                id="submit"
                                onClick={authClick}
                                value="CONNEXION"
                            />
                        </div>
                        <div className="login-form__bottom">
                            <a
                                href="/register"
                                className="button button--secondary button--full"
                            >

                                S'INSCRIRE
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Login;