import React, { useEffect, useState } from 'react';
import axios from "axios"
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import Stats from '../components/Stats';
import Training from '../components/Training';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Main = () => {

    const [nameGet, nameSet] = useState([]);

    useEffect(() => {

        nameSet(<Skeleton width={100} />);

        const headers = {
            headers: {
                tid: localStorage.getItem("tid"),
                ta: localStorage.getItem("ta")
            }
        };

        axios.get('https://api.nanacoaching.fr/users/', headers)
            .then(resp => {

                let name = resp.data.name;

                if (name !== undefined && name !== null) {

                    nameSet(name);

                } else {

                    window.location.href = "/home";

                }

            }).catch((error) => {
                console.log({ ...error })
            });

    }, []);

    const handleScroll = event => {

        alert(event.currentTarget.scrollTop);

    }

    return (

        <div className="page page--main" page="main" onScroll={handleScroll} >
            <Header />
            <div className="page__content page__content--with-header page__content--with-bottom-nav" id="home-page">
                <h2 className="page__title">Hello, {nameGet}</h2>
                <p className="welcome">
                    Faisont une scéance de sport ? Je peux te proposé plusieur programmes que ce sois pour une prise de masse, séche ou remis en forme et bien étre j'ai ce qu'il te faut !
                </p>
                <Training name="Chez vous!" url="https://api.nanacoaching.fr/trainings/home" />
                <Training name="Prise de muscle" url="https://api.nanacoaching.fr/trainings/gain" />
                <Training name="Remise en forme" url="https://api.nanacoaching.fr/trainings/fitness" />
                <Training name="Perte de poids" url="https://api.nanacoaching.fr/trainings/loss" />
                <Stats />
            </div>
            <Navigation />
        </div >
    );
};

export default Main;