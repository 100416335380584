import React, { Component } from 'react';
import axios from "axios"
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

class Training extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stats: [],
        };
    }

    componentDidMount() {

        const headers = {
            headers: {
                tid: localStorage.getItem("tid"),
                ta: localStorage.getItem("ta")
            }
        };

        axios.get('https://api.nanacoaching.fr/users/training/stats/', headers)
            .then(resp => {

                let statsData = resp.data;

                if (statsData !== undefined && statsData !== null) {

                    this.setState({
                        stats: statsData
                    });

                } else {

                    window.location.href = "/home";

                }

            }).catch((error) => {
                console.log({ ...error })
            });

    }


    render() {

        const options = {
            responsive: true,
            plugins: {
                legend: {
                    position: "top",
                    labels: {
                        boxWidth: 0,
                        padding: 5,
                    },
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            display: false,
                        },
                        gridLines: {
                            drawTicks: false,
                            display: false,
                            drawBorder: false,
                        },

                    }],
                    xAxes: [{
                        gridLines: {
                            zeroLineColor: "transparent",
                            display: false,
                            drawBorder: false,
                        },
                        ticks: {
                            padding: 0,
                            fontColor: "rgba(255,255,255,0.10)",
                            fontStyle: "bold",
                        },
                    }]
                },
            },
        };

        const labels = ["LUN", "MAR", "MER", "JEU", "VEN", "SAM", "DIM"];

        const data = {
            labels,
            datasets: [
                {
                    label: "",
                    borderColor: "#68fe9a",
                    pointBorderColor: "#68fe9a",
                    pointBackgroundColor: "rgba(255, 255, 255, 1)",
                    pointHoverBackgroundColor: "rgba(128, 182, 244, 1)",
                    pointHoverBorderColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(200, 0, 100, 0);
                        gradient.addColorStop(0, "rgba(104, 254, 154, 1)");
                        gradient.addColorStop(1, "rgba(104, 254, 154, 1)");
                        return gradient;
                    },
                    pointRadius: 2,
                    fill: true,
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 180);
                        gradient.addColorStop(0, "rgba(104, 254, 154, 0.5)");
                        gradient.addColorStop(1, "rgba(104, 254, 154, 0)");
                        return gradient;
                    },
                    borderWidth: 2,
                    data: [this.state.stats.lundi, this.state.stats.mardi, this.state.stats.mercredi, this.state.stats.jeudi, this.state.stats.samedi, this.state.stats.dimanche, 0],
                },
            ],
        };

        return (
            <>
                <div className="page__title-bar mb-20">
                    <h3 className="pb-0">Mes Statistique</h3>
                    <div className="page__title-right">
                        <button className="button button--main button--ex-small">JOURS D'ENTRAINEMENT</button>
                    </div>
                </div>
                <Line className="mb-20" width="100%" height="60" options={options} data={data} />
            </>
        );
    }
}

export default Training;