import React, { Component } from 'react';
import axios from "axios"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

class Training extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: props.name,
            url: props.url,
            trainigs: [],
        };
    }

    handleDataComponent() {

        window.updateSwiper();

    }

    componentDidMount() {

        const headers = {
            headers: {
                tid: localStorage.getItem("tid"),
                ta: localStorage.getItem("ta")
            }
        };

        this.setState({
            trainigs: (<>

                <Skeleton className='slider-cover--round-corners slider-cover__slide slider-cover caption'
                    width={100} height={200} />
                <Skeleton className='slider-cover--round-corners slider-cover__slide slider-cover caption'
                    width={100} height={200} />
                <Skeleton className='slider-cover--round-corners slider-cover__slide slider-cover caption'
                    width={100} height={200} />

            </>)
        });

        axios.get(this.state.url, headers)
            .then(resp => {

                let data = resp.data;

                if (data !== undefined && data !== null) {

                    this.setState({
                        trainigs: data.map((tr) =>
                            <div key={tr.id}
                                className="swiper-slide slider-cover__slide slider-cover__slide--2h"
                                style={{
                                    backgroundImage:
                                        "url(" + tr.img + ")"
                                }}
                            > <a href={tr.data}><div className="slider-cover__caption caption caption--gradient">
                                <div className="caption__content">
                                    <h2 className="caption__title">
                                        <strong>{tr.title}</strong>{tr.subtitle}
                                    </h2>
                                </div>
                            </div>
                                    <div className="slider-cover__more">
                                        <div>
                                            <img
                                                src="https://cdn-img.nanacoaching.fr/play.svg"
                                                alt=""
                                                title=""
                                            />
                                        </div>
                                    </div></a>
                            </div>)


                    });

                    this.handleDataComponent();

                } else {

                    window.location.href = "/home";

                }

            }).catch((error) => {
                console.log({ ...error })
            });

    }


    render() {

        return (
            <>
                <div className="page__title-bar">
                    <h3>{this.state.name}</h3>
                    <div className="page__title-right">
                        <div className="swiper-button-prev slider-cover__prev" />
                        <div className="swiper-button-next slider-cover__next" />
                    </div>
                </div>

                <div
                    className="swiper-container slider-cover slider-cover--round-corners slider-init mb-40"
                    data-paginationtype="progressbar"
                    data-spacebetweenitems={10}
                    data-itemsperview="auto"
                >
                    <div className="swiper-wrapper">

                        {this.state.trainigs}

                    </div>
                    <div className="swiper-pagination slider-cover__pagination" />
                </div>
            </>
        );
    }
}

export default Training;