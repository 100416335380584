import React from 'react';
import Header from '../components/Header';
import Navigation from '../components/Navigation';

const Shop = () => {
    return (
        <div className="page page--main" data-page="main">
            <Header />
            <div className="page__content page__content--with-header page__content--with-bottom-nav">
                <h2 className="page__title uppc">Nos Offres</h2>
                <p className="welcome">
                    Motivation is the catalyzing ingredient for every successful innovation
                </p>
                <div className="cards cards--11">
                    <div className="card card--style-icon card--style-round-corners">
                        <div className="card__plan">9,99€</div>
                        <h4 className="card__title card__title--centered">Basic</h4>
                        <ul className="list-unstyled mb-4">
                            <li>Accés au programmes à Domicile</li>
                            <li>Discution avec un Conseillié Sportif</li>
                            <li>Smart Sport Statistique</li>
                            <li>Sans Engagement</li>
                        </ul>
                        <div className="buttons buttons--centered">
                            <a href="contact.html" className="button button--main button--small">
                                S'abonner
                            </a>
                        </div>
                        <div className="card__top-right-info">
                            <div className="card__more">
                                <a href="/">
                                    <img
                                        src="../../assets/images/icons/gradient-purple/more.svg"
                                        alt=""
                                        title=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="card card--style-icon card--style-round-corners card--style-plan-premium">
                        <div className="card__plan card__plan--premium">19,99€</div>
                        <h4 className="card__title card__title--centered">Premium</h4>
                        <ul className="list-unstyled mb-4">
                            <li>Accés à tous nos programmes</li>
                            <li>Discution avec un Conseillié Sportif</li>
                            <li>Smart Sport Statistique</li>
                            <li>Sans Engagement</li>
                        </ul>
                        <div className="buttons buttons--centered">
                            <a href="contact.html" className="button button--blue button--small">
                                S'abonner
                            </a>
                        </div>
                        <div className="card__top-right-info">
                            <div className="card__more">
                                <a href="/">
                                    <img
                                        src="../../assets/images/icons/gradient-purple/more.svg"
                                        alt=""
                                        title=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="card card--style-icon card--style-round-corners">
                        <div className="card__plan">39,99€</div>
                        <h4 className="card__title card__title--centered">Platinium</h4>
                        <ul className="list-unstyled mb-4">
                            <li>Accés à tous nos programmes</li>
                            <li>Discution avec votre Coach Diplomée</li>
                            <li>Gestion de la récupération, Étirement Avancé (SANTÉ <i className="fa-solid fa-circle-plus health-more"></i>)</li>
                            <li>Smart Sport Statistique</li>
                            <li>Sans Engagement</li>
                        </ul>
                        <div className="buttons buttons--centered">
                            <a href="contact.html" className="button button--main button--small">
                                S'abonner
                            </a>
                        </div>
                        <div className="card__top-right-info">
                            <div className="card__more">
                                <a href="/">
                                    <img
                                        src="../../assets/images/icons/gradient-purple/more.svg"
                                        alt=""
                                        title=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Navigation />
        </div>
    );
};

export default Shop;