import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import axios from "axios";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import BackButton from '../components/BackButton';

const Exercises = () => {

    const uuid = (new URLSearchParams(window.location.search)).get("id");

    const [exerciseData, exerciseDataSet] = useState([]);
    const [infoData, infoDataSet] = useState([]);
    const [statusButtonData, statusButtonDataSet] = useState([]);

    function createTraining() {

        const json = JSON.stringify({ id: uuid });

        const headers = {
            headers: {
                tid: localStorage.getItem("tid"),
                ta: localStorage.getItem("ta")
            }
        };

        axios.post('https://api.nanacoaching.fr/users/training/create/', json, headers)
            .then(resp => {

                const MySwal = withReactContent(Swal)

                MySwal.fire({
                    title: "Nice!",
                    text: "Entrenement Commencé",
                    icon: 'success'
                })

                statusButtonDataSet(<div onClick={trainingFinishClick}>
                    <img
                        src={"https://cdn-img.nanacoaching.fr/pause.svg"}
                        alt=""
                        title=""
                    />
                </div>);

            }).catch((error) => {
                console.log({ ...error })
            });

    }

    function trainingClick(e) {

        const headers = {
            headers: {
                tid: localStorage.getItem("tid"),
                ta: localStorage.getItem("ta")
            }
        };

        axios.get('https://api.nanacoaching.fr/users/training/recovery/' + uuid, headers)
            .then(resp => {

                if (resp.data.recovery === true) {

                    const MySwal = withReactContent(Swal)

                    MySwal.fire({
                        title: "Attention",
                        text: "Vous avez éffectuer cette entrainement il y'a peu. Vous n'avez pas encore eu le temps de récupérer.",
                        showCancelButton: true,
                        icon: 'warning',
                    }).then(value => {
                        if (value.isConfirmed) {

                            createTraining();

                        }

                    });

                } else {

                    createTraining();

                }

            }).catch((error) => {
                console.log({ ...error })
            });


    }

    function trainingFinishClick(e) {

        const headers = {
            headers: {
                tid: localStorage.getItem("tid"),
                ta: localStorage.getItem("ta")
            }
        };

        axios.post('https://api.nanacoaching.fr/users/training/finish/', null, headers)
            .then(resp => {

                const MySwal = withReactContent(Swal)

                MySwal.fire({
                    title: "Nice!",
                    text: "Entrenement Terminée",
                    icon: 'success'
                })

                statusButtonDataSet(<div onClick={trainingClick}>
                    <img
                        src={"https://cdn-img.nanacoaching.fr/play-green.svg"}
                        alt=""
                        title=""
                    />
                </div>);

            }).catch((error) => {
                console.log({ ...error })
            });

    }

    useEffect(() => {
        const headers = {
            headers: {
                tid: localStorage.getItem("tid"),
                ta: localStorage.getItem("ta")
            }
        };

        axios.get('https://api.nanacoaching.fr/exercises/' + uuid, headers)
            .then(resp => {

                let data = resp.data;

                if (data !== undefined && data !== null) {

                    infoDataSet(data);
                    exerciseDataSet(data.exercises);

                } else {

                    window.location.href = "/home";

                }

            }).catch((error) => {
                console.log({ ...error })
            });

        axios.get('https://api.nanacoaching.fr/users/training/', headers)
            .then(resp => {

                let data = resp.data;

                if (data !== undefined && data !== null) {

                    let trainingId = data.trainingId;

                    if (uuid === trainingId) {

                        statusButtonDataSet(<div onClick={trainingFinishClick}>
                            <img
                                src={"https://cdn-img.nanacoaching.fr/pause.svg"}
                                alt=""
                                title=""
                            />
                        </div>);

                    } else {

                        statusButtonDataSet(<div onClick={trainingClick}>
                            <img
                                src={"https://cdn-img.nanacoaching.fr/play-green.svg"}
                                alt=""
                                title=""
                            />
                        </div>);

                    }

                } else {

                    window.location.href = "/home";

                }

            }).catch((error) => {
                console.log({ ...error })
            });
    }, []);

    return (
        <div className="page page--main" data-page="main">
            <Header />
            <div className="page__content page__content--with-header">
                <BackButton />
                <div className="cards cards--11">
                    <div
                        className="card card--style-inline card--style-inline-bg card--style-round-corners"
                        style={{
                            backgroundImage:
                                "url(" + infoData["imgTraining"] + ")",
                            backgroundPosition: "center"
                        }}
                    >
                        <div className="card__details">
                            <h4 className="card__title uppc">{infoData["titleSeance"]}</h4>
                            <p className="card__text uppc">{infoData["titleTraining"]}</p>
                        </div>
                        <div className="card__more card__more--play">
                            {statusButtonData}
                        </div>
                    </div>
                </div>
                <div className="cards cards--11">

                    {exerciseData.map((exercise, index) => {
                        if (exercise["type"] === "info") {
                            return (
                                <div key={index} className="bg-classic text-center card card--style-icon card--style-round-corners card-set">
                                    <h3 className="white-color">{exercise["title"]}</h3>
                                    <h3 className="white-color">{exercise["cat"]}</h3>
                                    <h5 className="white-color uppc">{exercise["desc"]}</h5>
                                </div>
                            )
                        } else {
                            return (
                                <div key={index} className="cards cards--11">
                                    <div className="recipe-card">
                                        <aside>
                                            <img src={exercise["img"]} alt=""></img>
                                        </aside>
                                        <article>
                                            <h2 className="uppc">{exercise["title"]}</h2>
                                            <ul>
                                                <li><span className="bx bx-dumbbell icones"></span><span className="uppc">Série: {exercise["serie"]}</span></li>
                                                <li><span className="bx bx-repost icones"></span><span className="uppc">Rép: {exercise["rep"]}</span></li>
                                                <li><span className="bx bx-signal-4 icones"></span><span className="uppc">Tempo: {exercise["tempo"]}</span></li>
                                            </ul>
                                            <ul>
                                                <li><span className="bx bxs-timer icones"></span><span className="uppc">Récup: {exercise["recup"]}</span></li>
                                                <li><span className="bx bxs-hot icones"></span><span className="uppc">Calorie: {exercise["cal"]}</span></li>
                                            </ul>
                                            <p className="ingredients"><span>Remarque:&nbsp;</span>{exercise["remarque"]}</p>
                                        </article>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
            <Navigation />
        </div >
    );
};

export default Exercises;