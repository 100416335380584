import React, { useEffect, useState, useRef } from 'react';
import axios from "axios"
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const User = () => {

    const [userGet, userSet] = useState([]);
    const userDataInputRef = useRef(null);

    const editWeightUser = (e) => {

        const MySwal = withReactContent(Swal);

        MySwal.fire({
            html: <>
                <h3 className='uppc black-color'>Saisir votre poids <br></br> (en kg)</h3>
                <input ref={userDataInputRef} defaultValue={userGet.weight} type={"number"} width={"40%"} min={40} max={600} step="1" />
            </>
        }).then(value => {
            callUpdateUserValue("WEIGHT", userDataInputRef.current.value);
        });
    }

    const editHeightUser = (e) => {

        const MySwal = withReactContent(Swal);

        MySwal.fire({
            html: <>
                <h3 className='uppc black-color'>Saisir votre taille <br></br> (en cm)</h3>
                <input ref={userDataInputRef} defaultValue={userGet.height} type={"number"} width={"40%"} min={120} max={250} step="1" />
            </>
        }).then(value => {
            callUpdateUserValue("HEIGHT", userDataInputRef.current.value);
        });
    }

    function callUpdateUserValue(targetType, data) {

        const headers = {
            headers: {
                tid: localStorage.getItem("tid"),
                ta: localStorage.getItem("ta")
            }
        };

        const json = JSON.stringify({ type: targetType, value: data });

        axios.post('https://api.nanacoaching.fr/users/info/', json, headers)
            .then(resp => {

                window.location.reload();

            }).catch((error) => {
                console.log({ ...error })
            });

    }

    useEffect(() => {

        const headers = {
            headers: {
                tid: localStorage.getItem("tid"),
                ta: localStorage.getItem("ta")
            }
        };

        axios.get('https://api.nanacoaching.fr/users/', headers)
            .then(resp => {

                let name = resp.data;

                if (name !== undefined && name !== null) {

                    userSet(name);

                } else {

                    window.location.href = "/home";

                }

            }).catch((error) => {
                console.log({ ...error })
            });

    }, []);

    return (
        <div className="page page--main" data-page="main">
            <Header />
            <div className="page__contents">
                <div className="user-profile mb-20">
                    <div className="user-profile__thumb">
                        <img
                            src="https://cdn-img.nanacoaching.fr/177660.png"
                            alt=""
                            title=""
                        />
                    </div>
                    <div className="user-profile__name">{userGet.name}</div>
                    <div className="buttons buttons--centered">
                        <div className="button button--main button--ex-small ml-10">
                            {userGet.userType}
                        </div>
                    </div>
                </div>
                <div className="page__title-bar">
                    <h3>Mes informations</h3>
                </div>
                <div className="cards cards--12">
                    <div className="card card--style-icon card--style-round-corners">
                        <div className="card__icon info">
                            <img
                                src="https://cdn-img.nanacoaching.fr/weight.svg"
                                alt=""
                                title=""
                            />
                        </div>
                        <h4 className="card__title info stats uppc">{userGet.weight} KG</h4>
                        <h5 className="card__title info uppc">Votre Poids</h5>
                        <div className="card__top-right-info">
                            <div className="card__more">
                                <div onClick={editWeightUser}>
                                    <img
                                        src="https://cdn-img.nanacoaching.fr/more.svg"
                                        alt=""
                                        title=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card card--style-icon card--style-round-corners">
                        <div className="card__icon info">
                            <img
                                src="https://cdn-img.nanacoaching.fr/jogging.svg"
                                alt=""
                                title=""
                            />
                        </div>
                        <h4 className="card__title info stats uppc">{userGet.height} CM</h4>
                        <h5 className="card__title info uppc">Votre Taile</h5>
                        <div className="card__top-right-info">
                            <div className="card__more">
                                <div onClick={editHeightUser}>
                                    <img
                                        src="https://cdn-img.nanacoaching.fr/more.svg"
                                        alt=""
                                        title=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card card--style-icon card--style-round-corners">
                        <div className="card__icon info">
                            <img
                                src="https://cdn-img.nanacoaching.fr/clock.svg"
                                alt=""
                                title=""
                            />
                        </div>
                        <h4 className="card__title info stats uppc">Vos Objectif</h4>
                        <p className="card__text">Je souaite gagner de la masse musculaire.</p>
                        <div className="card__top-right-info">
                            <div className="card__more">
                                <a href="/">
                                    <img
                                        src="https://cdn-img.nanacoaching.fr/more.svg"
                                        alt=""
                                        title=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Navigation />
        </div>
    );
};

export default User;