import React from 'react';
import Header from '../components/Header';
import Navigation from '../components/Navigation';

const Chat = () => {
    return (
        <div className="page page--main" data-page="main">
            <Header />
            <div className="page__content page__content--with-header page__content--with-bottom-nav">
                <div className="page__title-bar coach-list">
                    <h3 className="uppc">Nos Coach</h3>
                    <div className="page__title-right">
                        <div
                            className="swiper-button-prev slider-thumbs__prev swiper-button-prev1 swiper-button-disabled"
                            tabIndex={-1}
                            role="button"
                            aria-label="Previous slide"
                            aria-disabled="true"
                        />
                        <div
                            className="swiper-button-next slider-thumbs__next swiper-button-next1"
                            tabIndex={0}
                            role="button"
                            aria-label="Next slide"
                            aria-disabled="false"
                        />
                    </div>
                </div>
                <div
                    className="swiper-container slider-thumbs slider-init mb-20 s1 swiper-container-initialized swiper-container-horizontal swiper-container-ios"
                    data-paginationtype="progressbar"
                    data-spacebetweenitems={10}
                    data-itemsperview="auto"
                >
                    <div
                        className="swiper-wrapper"
                        style={{ transform: "translate3d(0px, 0px, 0px)" }}
                    >
                        <div
                            className="swiper-slide slider-thumbs__slide slider-thumbs__slide--4h swiper-slide-active"
                            style={{ marginRight: 10 }}
                        >
                            <div className="slider-thumbs__image slider-thumbs__image--round-corners-full">
                                <span className="active-user" />
                                <a href="/">
                                    <img
                                        src="https://scontent.fcdg4-1.fna.fbcdn.net/v/t1.6435-9/95147023_1069757016727384_6917432863323324416_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=-HCo-FMF_AcAX92EHBQ&_nc_ht=scontent.fcdg4-1.fna&oh=00_AT9DOMK-bJB3NFr1UGQkORNtJR3tY9lX43X0sK63woJjYw&oe=630C611D"
                                        alt=""
                                        title=""
                                    />
                                </a>
                            </div>
                            <div className="slider-thumbs__caption caption">
                                <div className="caption__content">
                                    <h2 className="caption__title caption__title--smaller caption__title--centered">
                                        <strong>Anaelle </strong>Daffos
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-pagination slider-thumbs__pagination swiper-pagination1 swiper-pagination-progressbar">
                        <span
                            className="swiper-pagination-progressbar-fill"
                            style={{
                                transform: "translate3d(0px, 0px, 0px) scaleX(0.2) scaleY(1)",
                                transitionDuration: "300ms"
                            }}
                        />
                    </div>
                    <span
                        className="swiper-notification"
                        aria-live="assertive"
                        aria-atomic="true"
                    />
                </div>
            </div>
            <Navigation />
        </div>
    );
};

export default Chat;