import React from 'react';

const Home = () => {
    return (
        <div className="page page--splash">
            <div className="splash">
                <div className="splash__content">
                    <div className="splash__logo">
                        Nana<strong>Coaching</strong>
                    </div>
                    <div className="splash__text">
                        Connectez-vous à votre compte ou inscrivez-vous, créer un nouveau compte
                    </div>
                    <div className="splash__buttons">
                        <a href="/login" className="button button--full button--main">
                            Connexion
                        </a>
                        <a href="/register" className="button button--full button--secondary">
                            Incription
                        </a>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Home;