import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import axios from "axios";
import BackButton from '../components/BackButton';

const Seances = () => {

    const id = (new URLSearchParams(window.location.search)).get("id");

    const [seanceData, seanceDataSet] = useState([]);
    const [infoData, infoDataSet] = useState([]);

    useEffect(() => {
        const headers = {
            headers: {
                tid: localStorage.getItem("tid"),
                ta: localStorage.getItem("ta")
            }
        };

        axios.get('https://api.nanacoaching.fr/seances/' + id, headers)
            .then(resp => {

                let data = resp.data;

                if (data !== undefined && data !== null) {

                    infoDataSet(data);
                    seanceDataSet(data.seances);

                } else {

                    window.location.href = "/home";

                }

            }).catch((error) => {
                console.log({ ...error })
            });
    }, []);

    return (
        <div className="page page--main" data-page="main">
            <Header />
            <div className="page__content page__content--with-header">
                <BackButton />
                <div className="cards cards--11">
                    <div
                        className="card card--style-icon card--style-round-corners"
                        style={{
                            backgroundImage:
                                "url(" + infoData["imgTraining"] + ")",
                            backgroundPosition: "center"
                        }}
                    >
                        <h4 className="card__title card__title--centered uppc">{infoData["titleTraining"]}</h4>
                        <p className="card__text card__text--centered uppc">
                            {infoData["subtitleTraining"]}
                        </p>
                    </div>
                </div>
                <p className="welcome">
                    Motivation is the catalyzing ingredient for every successful innovation
                </p>
                <div className="cards cards--11">
                    {seanceData.map((seance) =>
                        <div key={seance.id} className="card card--style-inline card--style-inline-bg card--style-round-corners">
                            <div className="card__icon">
                                <img
                                    src={"https://cdn-img.nanacoaching.fr/" + seance.type + ".svg"}
                                    alt=""
                                    title=""
                                />
                            </div>
                            <div className="card__details">
                                <h4 className="card__title">{seance.title}</h4>
                                <p className="card__text">
                                    {seance.desc}
                                </p>
                            </div>
                            <div className="card__more card__more--play">
                                <a href={seance.data}>
                                    <img
                                        src="https://cdn-img.nanacoaching.fr/play-green.svg"
                                        alt=""
                                        title=""
                                    />
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Navigation />
        </div>
    );
};

export default Seances;