import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { CookiesProvider } from "react-cookie";
import "./styles/index.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

/*window.addEventListener(
  'touchmove',
  () => {
    alert("test");
  },
  { capture: true, passive: false }
);*/

function scrollToPreventBounce(htmlElement) {
  const { scrollTop, offsetHeight, scrollHeight } = htmlElement;

  // If at top, bump down 1px
  if (scrollTop <= 0) {
    htmlElement.scrollTo(0, 1);
    return;
  }

  // If at bottom, bump up 1px
  if (scrollTop + offsetHeight >= scrollHeight) {
    htmlElement.scrollTo(0, scrollHeight - offsetHeight - 1);
  }
}
window.addEventListener('touchstart', scrollToPreventBounce);


root.render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>
);
