import React from 'react';
import { NavLink } from "react-router-dom"

const Header = () => {
    return (
        <header className="header header--page header--fixed">
            <div className="header__inner">
                <div className="header__logo header__logo--text uppc">
                    <NavLink to="/">
                        Nana<strong className="uppc">Coaching</strong>
                    </NavLink>
                </div>
                <div className="header__icon header__icon--user">
                    <NavLink to="/user">
                        <img src="https://cdn-img.nanacoaching.fr/177660.png" alt="" title=""></img>
                    </NavLink>
                </div>
            </div>
        </header>
    );
};

export default Header;