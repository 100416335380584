import React from 'react';
import { NavLink } from "react-router-dom"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";

const Navigation = () => {

    function trainingClick(e) {

        const headers = {
            headers: {
                tid: localStorage.getItem("tid"),
                ta: localStorage.getItem("ta")
            }
        };

        axios.get('https://api.nanacoaching.fr/users/training/', headers)
            .then(resp => {

                let trainingId = resp.data.trainingId;

                if (trainingId !== undefined && trainingId !== null) {

                    window.location.href = "/exercises?id=" + trainingId;

                } else {

                    const MySwal = withReactContent(Swal)

                    MySwal.fire({
                        title: "Oups...",
                        text: "Aucun n'entrainement commencer !",
                        icon: 'error'
                    })

                }

            }).catch((error) => {
                console.log({ ...error })
            });

    }

    return (
        <>
            <div className="down-spacer"></div>
            <div id="bottom-toolbar" className="bottom-toolbar">
                <div className="bottom-navigation bottom-navigation--gradient">
                    <ul className="bottom-navigation__icons">
                        <li>
                            <NavLink to="/">
                                <img src="https://cdn-img.nanacoaching.fr/home.svg" alt="" title=""></img>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/user">
                                <img src="https://cdn-img.nanacoaching.fr/user.svg" alt="" title=""></img>
                            </NavLink>
                        </li>
                        <li className="centered" onClick={trainingClick}>
                            <img src="https://cdn-img.nanacoaching.fr/5220670.png" alt="" title=""></img>
                        </li>
                        <li>
                            <NavLink to="/shop">
                                <img src="https://cdn-img.nanacoaching.fr/cart.svg" alt="" title=""></img>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/chat">
                                <img src="https://cdn-img.nanacoaching.fr/chat.svg" alt="" title=""></img>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Navigation;