import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";
import { useRef } from 'react';

const Register = () => {

    const emailRef = useRef(null);
    const nameRef = useRef(null);
    const passwordRef = useRef(null);

    function registerClick(e) {

        const json = JSON.stringify({ email: emailRef.current.value, password: passwordRef.current.value, name: nameRef.current.value });

        axios.put('https://api.nanacoaching.fr/users/register/', json)
            .then(resp => {

                let id = resp.data.id;

                if (id !== undefined && id !== null) {

                    localStorage.setItem("tid", id);
                    localStorage.setItem("ta", emailRef.current.value);

                    const MySwal = withReactContent(Swal)

                    MySwal.fire({
                        title: "Parfait",
                        text: "Vous avez bien été identifier !",
                        icon: 'success'
                    }).then(() => {
                        window.location.href = "/";
                    })


                } else {

                    const MySwal = withReactContent(Swal)

                    MySwal.fire({
                        title: "Oups...",
                        text: "Votre Mot de passe, email ou nom est invalide !",
                        icon: 'error'
                    })

                }

            }).catch((error) => {
                console.log({ ...error })
            });

    }

    return (
        <div className="page page--login" data-page="login">
            {/* HEADER */}
            <header className="header header--fixed">
                <div className="header__inner">
                    <div className="header__icon">
                        <a href="/home">
                            <img
                                src="https://cdn-img.nanacoaching.fr/arrow-back.svg"
                                alt=""
                                title=""
                            />
                        </a>
                    </div>
                </div>
            </header>
            <div className="login">
                <div className="login__content">
                    <h2 className="login__title">Créer ton compte</h2>
                    <div className="login-form">
                        <div className="login-form__row">
                            <label className="login-form__label">Nom & Prénom</label>
                            <input
                                ref={nameRef}
                                type="text"
                                name="Username"
                                defaultValue=""
                                className="login-form__input required"
                            />
                        </div>
                        <div className="login-form__row">
                            <label className="login-form__label">Email</label>
                            <input
                                ref={emailRef}
                                type="text"
                                name="email"
                                defaultValue=""
                                className="login-form__input required email"
                            />
                        </div>
                        <div className="login-form__row">
                            <label className="login-form__label">Mot de passe</label>
                            <input
                                ref={passwordRef}
                                type="password"
                                name="password"
                                defaultValue=""
                                className="login-form__input required"
                            />
                        </div>
                        <div className="login-form__row">
                            <input
                                type="submit"
                                name="submit"
                                className="login-form__submit button button--main button--full"
                                id="submit"
                                onClick={registerClick}
                                value="CONNEXION"
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
};

export default Register;