import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {

    const navigate = useNavigate();

    return (
        <div className="header__icon back">
            <button className='btn-hide' onClick={() => navigate(-1)}>
                <img
                    src="https://cdn-img.nanacoaching.fr/arrow-back.svg"
                    alt=""
                    title=""
                />
            </button>
        </div>
    );
};

export default BackButton;